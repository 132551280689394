import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { useState } from 'react'

const MarkerIcon = ({ className }) => <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="12" y="11" width="0.01" height="0.01" stroke="#292929" stroke-width="3" stroke-linejoin="round" />
    <path d="M12 22L17.5 16.5C20.5376 13.4624 20.5376 8.53757 17.5 5.5C14.4624 2.46244 9.53757 2.46244 6.5 5.5C3.46244 8.53757 3.46244 13.4624 6.5 16.5L12 22Z" stroke="#292929" stroke-width="2" stroke-linejoin="round" />
</svg>

const InputLocation = (props) => {
    const {
        value = null,
        countries,
        onChange = () => { }
    } = props
    const [searchText, setSearchText] = useState(value?.label || "")

    return <div className='relative'>
        <GooglePlacesAutocomplete
            apiKey={'AIzaSyBW6PmAbGxAU_01g4CHlZ4oaYthN0dia3c'}
            autocompletionRequest={{
                componentRestrictions: {
                    country: ['us', 'ca'],
                }
            }}
            selectProps={{
                value: !!value ? value : null,
                placeholder: !!countries.filter(c => c.key === 'us' && !!c.value).length ? "City, Zip Code" : "City, Postal Code",
                onChange: (val) => {
                    geocodeByPlaceId(val.value.place_id)
                        .then(results => {
                            if (!!!results.length) return;

                            // let postalCode = results[0]?.address_components?.filter(
                            //     (ac) => ac.types.indexOf("postal_code") !== -1
                            // );
                            // console.log("postalCodepostalCode", postalCode)
                            const addressComponents = results[0].address_components || [];
                            const postalCode = addressComponents.find(ac => ac.types.includes("postal_code"))?.long_name || null;
                            let city = addressComponents?.find(
                                (ac) => ac.types.includes("sublocality_level_1")
                            )?.long_name || 
                                addressComponents?.find(
                                    (ac) => ac.types.includes("locality")
                                )?.long_name;
                            const state = addressComponents.find(ac => ac.types.includes("administrative_area_level_1"))?.long_name || null;
                            console.log("addressComponents", addressComponents);
                            console.log("psssCodee", postalCode);
                            console.log("city", city);
                            console.log("state", state);
                                                    
                            onChange("location", {
                                ...val,
                                postalCode: postalCode,
                                city,
                                state,
                                latitude: results[0].geometry.location.lat(),
                                longitude: results[0].geometry.location.lng()
                            })
                        })
                        .catch(error => console.error(error));
                },
                onInputChange: (val) => {
                    setSearchText(val)
                },
                styles: {
                    input: (provided) => ({
                        ...provided,
                        paddingTop: "0.375rem",
                        paddingBottom: "0.375rem",
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                    }),
                    placeholder: (provided) => ({
                        ...provided,
                        paddingLeft: "2rem"
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        paddingLeft: "2rem",
                        paddingRight: "2rem"
                    }),
                    menu: (provided, props) => ({
                        ...provided,
                        boxShadow: "none",
                        filter: `drop-shadow(0px 3px 8px rgba(35, 35, 35, 0.3))`,
                        display: !!props.options.length ? 'unset' : 'none'
                    }),
                    option: (provided) => ({
                        ...provided,
                        position: "relative",
                        paddingLeft: "44px",
                        paddingRight: "17px",
                        cursor: "pointer",
                        transition: ".1s",
                        ":before": {
                            content: `""`,
                            position: "absolute",
                            left: "17px",
                            top: "10px",
                            width: "18px",
                            height: "21px",
                            backgroundImage: `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='12' y='11' width='0.01' height='0.01' stroke='%23292929' stroke-width='3' stroke-linejoin='round'/%3E%3Cpath d='M12 22L17.5 16.5C20.5376 13.4624 20.5376 8.53757 17.5 5.5C14.4624 2.46244 9.53757 2.46244 6.5 5.5C3.46244 8.53757 3.46244 13.4624 6.5 16.5L12 22Z' stroke='%23292929' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E")`,
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                        },
                        ":hover": {
                            backgroundColor: "#1C7FB9",
                            color: "#FFF",
                            ":before": {
                                backgroundImage: `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='12' y='11' width='0.01' height='0.01' stroke='%23FFF' stroke-width='3' stroke-linejoin='round'/%3E%3Cpath d='M12 22L17.5 16.5C20.5376 13.4624 20.5376 8.53757 17.5 5.5C14.4624 2.46244 9.53757 2.46244 6.5 5.5C3.46244 8.53757 3.46244 13.4624 6.5 16.5L12 22Z' stroke='%23FFF' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E")`,
                            }
                        },
                        ":leave": {
                            backgroundColor: "#1C7FB9",
                            color: "#FFF",
                            ":before": {
                                backgroundImage: `url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='12' y='11' width='0.01' height='0.01' stroke='%23FFF' stroke-width='3' stroke-linejoin='round'/%3E%3Cpath d='M12 22L17.5 16.5C20.5376 13.4624 20.5376 8.53757 17.5 5.5C14.4624 2.46244 9.53757 2.46244 6.5 5.5C3.46244 8.53757 3.46244 13.4624 6.5 16.5L12 22Z' stroke='%23FFF' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E")`,
                            }
                        }
                    }),
                   
                    dropdownIndicator: (provided) => ({
                        ...provided,
                        display: 'none'
                    }),
                    indicatorSeparator: (provided) => ({
                        ...provided,
                        display: 'none'
                    }),
                    loadingIndicator: (provided) => ({
                        ...provided,
                        marginRight: "2rem"
                    }),
                }
            }}
        />
        <MarkerIcon className={"absolute top-2.5 left-3"} />
        {(!!searchText || !!value) && <button className='absolute top-3.5 right-3.5 p-1' onClick={() => {
            setSearchText('')
            onChange("location", null)
        }}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L11 11M1 11L11 1" stroke="#292929" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </button>}
    </div>
}

export default InputLocation