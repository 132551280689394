/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Icons from "../icons";
import ProfileSetting from "./ProfileSetting";
import SavedAndAppliedJobs from "./SavedAndAppliedJobs";

const JobsAndProfileSidebar = ({
  isDesktopFilterTagsClicked = false,
  setIsDesktopFilterTagsClicked = () => { },
  onSidebarOpen = () => { },
  setIsJob,
  isAllJob
}) => {
  const [activeSection, setActiveSection] = useState("");
  const sidebarRef = useRef(null);
  const sidebarIconClass =
    "relative w-10 h-10 flex items-center justify-center cursor-pointer";
  const ActiveLine = () => (
    <div className="h-full w-0.5 bg-primary-blue absolute -right-3 top-0" />
  );

  useEffect(() => {
    onSidebarOpen(!!activeSection)
  }, [activeSection])

  useEffect(() => {
    if (isDesktopFilterTagsClicked) {
      setActiveSection("profile");
      setIsDesktopFilterTagsClicked(false);
    }
  }, [isDesktopFilterTagsClicked]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOnCrossClick = () => setActiveSection("");

  const handleClickOutside = (event) => {
    const current = sidebarRef?.current;
    if (
      !!current &&
      !current?.contains(event.target) &&
      !current.querySelectorAll(".modal")?.length
    ) {
      setActiveSection("");
    }
  };

  return (
    <div className="flex bg-white" ref={sidebarRef}>
      <div className="h-full px-3 text-gray-800 border-x">
        <div
          className={`mt-4 ${sidebarIconClass} ${activeSection === "jobs" ? "rounded bg-light-grey-blue-03" : ""
            }`}
          onClick={() => {setActiveSection(activeSection === "jobs" ? "" : "jobs")
          }}>
          <button>
            <Icons.SvgJob />
            {activeSection === "jobs" && <ActiveLine />}
          </button>
        </div>
        <div
          className={`mt-6 ${sidebarIconClass} ${activeSection === "profile" ? "rounded bg-light-grey-blue-03" : ""
            }
          `}
          onClick={() => setActiveSection(activeSection === "profile" ? "" : "profile")}>
          <button>
            <Icons.SvgProfile />
            {activeSection === "profile" && <ActiveLine />}
          </button>
        </div>
        <div
          className={`mt-6 ${sidebarIconClass} ${activeSection === "alljob" ? "rounded bg-light-grey-blue-03" : ""
            }
          `}
          onClick={() => {setActiveSection(activeSection === "alljob" ? "" : "alljob")
            setIsJob(!isAllJob);
          }}>
          <button>
            <Icons.SvgDollarIcon  color={isAllJob ? "#1C7FB9" : "grey"} />
            {activeSection === "alljob" && <ActiveLine />}
          </button>
        </div>
      </div>
      {activeSection !== 'alljob' &&
      <div
        className="relative flex flex-col transition-width"
        style={{ width: activeSection ? "412px" : "0px" }}>
        {activeSection === "jobs" && (
          <SavedAndAppliedJobs showCrossIcon={true} onCrossClick={handleOnCrossClick} />
        )}
        {activeSection === "profile" && (
          <ProfileSetting showCrossIcon={true} onCrossClick={handleOnCrossClick} isAllJob={isAllJob} />
        )}
      </div>
}
    </div>
  );
};

export default JobsAndProfileSidebar;
