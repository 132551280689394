import { useState } from 'react';
import MainHeader from '../common/blocks/MainHeader';
import useBreakpoint from '../hooks/useBreakpoint';
import JobsAndProfileSidebar from './JobsAndProfileSidebar';
import JobsListing from './JobsListing';
import UpdateJobLocationModal from '../common/modals/UpdateJobLocationModal';
import UpdateJobTypeModal from '../common/modals/UpdateJobTypeModal';
import useApplicationContext from '../context/ApplicationContext';

const MainSection = () => {
  const isDesktop = useBreakpoint('md');
  const [isMobileFilterTagsClicked, setIsMobileFilterTagsClicked] =
    useState(false);
  const [isDesktopFilterTagsClicked, setIsDesktopFilterTagsClicked] =
    useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const {
    showUpdateJobTypeModal,
    setShowUpdateJobTypeModal,
    showUpdateJobLocationModal,
    setShowUpdateJobLocationModal,
    setIsAllJob,
    isAllJob
  } = useApplicationContext();

  return (
    <div className='flex flex-col max-h-screen min-h-screen'>
      <MainHeader
        isMobileFilterTagsClicked={isMobileFilterTagsClicked}
        setIsMobileFilterTagsClicked={setIsMobileFilterTagsClicked}
        setIsDesktopFilterTagsClicked={setIsDesktopFilterTagsClicked}
        isAllJob={isAllJob}
      />
      <div className='relative flex flex-1 overflow-hidden'>
        <JobsListing
          OnMobileFilterTagsClick={() => setIsMobileFilterTagsClicked(true)}
          isSidebarOpen={isSidebarOpen}
        />

        {!!isDesktop && (
          <JobsAndProfileSidebar
            isDesktopFilterTagsClicked={isDesktopFilterTagsClicked}
            setIsDesktopFilterTagsClicked={setIsDesktopFilterTagsClicked}
            onSidebarOpen={setIsSidebarOpen}
            setIsJob={setIsAllJob}
            isAllJob={isAllJob}
          />
        )}
      </div>
      <UpdateJobLocationModal
        isVisible={showUpdateJobLocationModal}
        onCrossClick={() => setShowUpdateJobLocationModal(false)}
      />
      <UpdateJobTypeModal
        isVisible={showUpdateJobTypeModal}
        onCrossClick={() => setShowUpdateJobTypeModal(false)}
      />
    </div>
  );
};

export default MainSection;
