import { useEffect, useState } from 'react';
import ProfileSetting from '../../components/ProfileSetting';
import SavedAndAppliedJobs from '../../components/SavedAndAppliedJobs';
import useBreakpoint from '../../hooks/useBreakpoint';
import Icons from '../../icons';
import FilterTagsCarousel from '../jobs/FilterTagsCarousel';

const MainHeader = ({
  isMobileFilterTagsClicked = false,
  setIsMobileFilterTagsClicked = () => {},
  setIsDesktopFilterTagsClicked = () => {},
  isAllJob
}) => {
  const isDesktop = useBreakpoint('md');
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    window.addEventListener('hashchange', handleNavigateEvent);
    return () => window.removeEventListener('hashchange', handleNavigateEvent);
  }, []);

  useEffect(() => {
    // add hash to url on active section - to handle back button
    if (activeSection) {
      window.location.hash = activeSection;
    } else {
      // remove hash from url
      window.history.pushState('', document.title, window.location.pathname);
    }
  }, [activeSection]);

  useEffect(() => {
    isMobileFilterTagsClicked && setActiveSection('profile');
  }, [isMobileFilterTagsClicked]);

  const handleNavigateEvent = () => {
    !window.location.hash && setActiveSection('');
  };

  const handleOnBackClick = () => {
    setActiveSection('');
    !isDesktop && setIsMobileFilterTagsClicked(false);
  };

  const handleDesktopFilterClick = (e) => {
    e.stopPropagation(); // to cancel parent event
    setIsDesktopFilterTagsClicked(true);
  };

  return (
    <div
      className='sticky top-0 left-0 w-full pb-3 bg-white border-b border-gray-300 md:px-0 md:pb-6 pt-11'
      style={{ zIndex: 9000 }}
    >
      <div className='flex items-center justify-between px-4 md:px-16'>
        <a href='/'>
          <Icons.SvgSwobLogo
            className='mr-8'
            height={isDesktop ? 42 : 24}
            width={isDesktop ? 142 : 82}
          />
        </a>
        <div className='flex items-center overflow-hidden max-w-filter-wrapper'>
          {isDesktop ? (
            <>
              <p className='mr-4 text-gray-900 cursor-pointer'>Filter</p>
              <div className='pl-2 overflow-hidden'>
                <FilterTagsCarousel
                  tagClassName='!cursor-grab'
                  className='-ml-2 !flex-nowrap overflow-x-scroll hide-scrollbar'
                  isAllJob={isAllJob}
                />
              </div>
            </>
          ) : (
            <>
              <Icons.SvgJob
                className='mr-6'
                onClick={() => setActiveSection('jobs')}
              />
              <Icons.SvgProfile onClick={() => setActiveSection('profile')} />
            </>
          )}
        </div>
      </div>
      <div
        className={`bg-white fixed top-0 right-0 h-full transition-width ${
          activeSection ? 'w-full' : 'w-0'
        }`}
      >
        {activeSection == 'jobs' && (
          <SavedAndAppliedJobs
            showBackIcon={true}
            onBackClick={handleOnBackClick}
          />
        )}
        {activeSection == 'profile' && (
          <ProfileSetting
            showBackIcon={true}
            onBackClick={handleOnBackClick}
            isAllJob={isAllJob}
          />
        )}
      </div>
    </div>
  );
};

export default MainHeader;
